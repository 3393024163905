import React, { useEffect, useState } from "react";
import { Flex, Input, Button } from "@chakra-ui/react";
import axios from "axios";
import { SendIcon } from "chakra-ui-ionicons";
import SpeechToTextHook from "./SpeechToTextHook";
import { URL } from "../utils/constant";

const ChatbotFooter = ({
  inputMessage,
  setInputMessage,
  handleSendMessage,
  setMessages,
  disable,
  answers,
  formatedQuestions,
  sendButtonEnable,
}) => {
  const isInputEmpty = inputMessage?.trim().length > 0;
  const [voiceConvertedText, setVoiceConvertedText] = useState();
  const [recording, setRecording] = useState();
  const url = URL;
  const apiCall = async () => {
    if (voiceConvertedText?.length) {
      let answer = {
        answers: [
          {
            questionId: formatedQuestions[answers.length]?.id,
            answer: voiceConvertedText,
            qNo: answers.length + 1,
          },
        ],
      };
      return axios.post(`${URL}/save-answers`, answer);
    }
  };

  useEffect(() => {
    if (voiceConvertedText?.length && !recording) {
      setMessages((old) => [
        ...old,
        { from: "me", text: voiceConvertedText, type: "text" },
      ]);
      apiCall().then(() => {
        if (answers.length < formatedQuestions.length - 1) {
          setMessages((old) => [...old, formatedQuestions[answers.length + 1]]);
        }

        // checks for last answer, answers array starts from 0
        if (answers.length === formatedQuestions.length - 1) {
          setTimeout(() => {
            setMessages((old) => [
              ...old,
              { from: "Computer", text: "Thank you for your response!" },
            ]);
          }, 1000);
          // SaveAnswers(previousAnswers); // open this and change previosuAnswer to send all questions all together
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording, voiceConvertedText]); // Send messages when ever new voice message comes in
  return (
    <Flex
      w="100%"
      mt="5"
      display={"flex"}
      align={"center"}
      justifyContent={"center"}
      sx={{
        position: "sticky",
        bottom: 0,
        background: "#f1f2f9",
        marginBottom: "10px",
        justifyContent: sendButtonEnable ? "center" : "flex-end",
        marginRight: 20,
        minH: "48px",
      }}
    >
      {/* {Chatbot Input bar} */}
      {sendButtonEnable ? (
        <Button
          colorScheme="teal"
          size="lg"
          w={250}
          onClick={handleSendMessage}
          isDisabled={disable}
        >
          Send
        </Button>
      ) : (
        <Input
          placeholder="Type Something..."
          borderRadius="5px"
          maxW={"320px"}
          _focus={{
            border: "1px solid black",
          }}
          isDisabled={disable}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
          maxLength={250}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
        />
      )}
      {(sendButtonEnable ? null : isInputEmpty || disable) && (
        <Button
          rightIcon={<SendIcon w={8} h={8} color="black" />}
          variant="solid"
          background={"transparent"}
          className="sendButton"
          _hover={{ background: "transparent" }}
          onClick={handleSendMessage}
          isDisabled={disable}
        />
      )}
    </Flex>
  );
};

export default ChatbotFooter;
