import React, { useState } from "react";
import { Avatar, Box, Flex, Text, Image } from "@chakra-ui/react";
import { chatbotImgUrl } from "../utils/constant";

export const MessageRenderer = ({ image, description }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useState(() => {
    setTimeout(() => {
      setShowDescription(true);
    }, 1000);
    setTimeout(() => {
    }, 2000);
    setShowImage(true);
  }, []);

  return (
    <Flex w="100%" mt={"5px"} sx={{ alignItems: "flex-end" }}>
      <Avatar name="Computer" src={chatbotImgUrl} bg="blue.300" mr={5}></Avatar>
      <Flex display={"block"} align={"center"}>
        {image && showImage && (
          <Box>
            <Flex
              bg="white"
              minW="100px"
              maxW="350px"
              my="1"
              p="3"
              borderRadius={"16px"}
            >
              <Image src={image} />
            </Flex>
          </Box>
        )}
        {description && showDescription && (
          <Box>
            <Flex
              bg="white"
              minW="100px"
              maxW="350px"
              my="1"
              p="3"
              borderRadius={"16px"}
            >
              <Text style={{ whiteSpace: "pre-line" }}>{description}</Text>
            </Flex>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
