import React, { useEffect, useLayoutEffect, useState } from "react";
import ChatbotFooter from "./Footer";
import Messages from "../components/Messages";
import { Box } from "@chakra-ui/react";
import axios from "axios";
import { URL } from "../utils/constant";
import { useSearchParams } from "react-router-dom";
import { surveys } from "../utils/constant";

function Chatbot({ questions, screenerQuestions, data }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const propsQuestions = [...questions];
  const [searchParams, _] = useSearchParams();
  const [updatedQuestion, setUpdatedQuestion] = useState([]);
  const surveyId = searchParams.get("surveyId");
  const clientId = searchParams.get("clientId");
  const surveyName = searchParams.get("survey");
  const lang = searchParams.get("lang");
  const [gender, setGender] = useState("");
  const [genderNo] = useState(data?.genderNo || []);
  const [inputMessage, setInputMessage] = useState(""); // input message state manager
  const [multiselect, setMultiselect] = useState(false);
  const [userAge, setUserAge] = useState("");
  const [repliedMultiselect, setRepliedMultiselect] = useState(0);
  const [notAgreedDisable, setNotAgreedDisable] = useState(false);
  const [sendButtonEnable, setSendButtonEnable] = useState(false);
  const [answerNo, setAnswerNo] = useState(1);
  const [msgNo, setMsgNo] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const ProductNameFormatter = (question) => {
    return question?.includes("{{productName}}")
      ? question?.replaceAll("{{productName}}", data?.name)
      : question;
  };
  const multiselectQuestion =
    lang === "jap"
      ? "この商品が発売されるとしたらどのくらいの確率でしょうか"
      : "If this product becomes available how likely would it be ";

  const [temp, setTemp] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTemp(false);
    }, 3000);
  }, []);
  useEffect(() => {
    if (!sessionId) {
      setSessionId(Date.now());
    }

    const updatedQuestions = propsQuestions
      .filter((val) => val.isNested)
      .map((data) => {
        const { isScreener, nestedQuestion, question_type, question, ...rest } =
          data;
        const nestedData = nestedQuestion.map((item, ind) => {
          return {
            isScreener,
            question_type,
            nestedQuestion: item,
            ...rest,
          };
        });
        nestedData[0].question = question;
        return { ...nestedData };
      });
    const indexOfNested = propsQuestions.findIndex(
      (item) => item.isNested === true
    );
    const propsQuestionWithoutNested = propsQuestions.filter(
      (item) => !item.isNested
    );
    // propsQuestions.splice(indexOfNested, 1);
    const updatedArr = [...propsQuestionWithoutNested, ...updatedQuestions];

    const transformedArray = Object.values(updatedArr).map((obj) => {
      if (obj.sequence !== undefined) {
        return obj;
      }
      return Object.values(obj);
    });
    setUpdatedQuestion(transformedArray.flatMap((item) => item));
  }, [questions, temp]);

  const questionUpdated = updatedQuestion.sort(
    (a, b) => a.sequence - b.sequence
  );

  const questionere = surveys?.[surveyName]
    ? screenerQuestions.length
      ? [...screenerQuestions, ...questionUpdated]
      : [...questionUpdated]
    : questions;
  const formatedQuestions = questionere
    ?.filter((val) => val?.active !== "no")
    ?.map((item, index) => ({
      ...item,
      from: "Computer",
      text: item?.question,
      id: item?.questionId,
      // isSubLast: index === 4 ? true : false,
      // isLastScreenerQuestion:
      //   index + 1 === screenerQuestions.length ? true : false,
      age: index === 0 ? true : false,
      question: ProductNameFormatter(item?.question),
      // isLastQuestion: index === questionere.length - 1 ? true : false,
    }));
  const [messages, setMessages] = useState([formatedQuestions[0]]); // initialMessage is initial templete message of chatbot
  let answers = messages?.filter((item) => item?.from === "me");
  var isInputDisabled = answers.length >= formatedQuestions.length;

  const isNotAgreedFunction = () => {
    setNotAgreedDisable(true);
  };
  const SaveAnswers = (answer) => {
    axios
      .post(`${URL}/save-answers`, answer)
      .then((res) => {})
      .catch((err) => err);
  };
  const SaveAnswersToSheet = (answer) => {
    axios({
      method: "post",
      url: `${URL}/save-answer-to-spreadSheet`,
      params: {
        sheetId: data?.sheetId,
        tabId: data?.tabId,
        answer: answer,
        answerNo: `Ans${answerNo}`,
        sessionId: sessionId,
      },
    })
      .then(function (response) {
        return response;
      })
      .catch((err) => {
        return err;
      });
    setAnswerNo((prev) => Number(prev) + 1);
  };
  const updateSurvey = () => {
    axios({
      method: "post",
      url: `${URL}/update-survey`,
      params: {
        clientId,
        surveyId,
        surveyName,
        genderNo: genderNo,
      },
    })
      .then(function (response) {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  const isAgeValid = (value) => {
    return value !== "Less than 45" && value !== "未満 45";
  };

  const optionData = () => {
    const option =
      lang === "eng"
        ? [
            "Definitely I would",
            "Probably I would",
            "May or may not",
            "Probably I would not",
            "Definitely I would not",
          ]
        : [
            "きっと手配をお願いする",
            "たぶん手配をお願いする",
            "手配のお願いをするかどうかわからない",
            "たぶん手配をお願いしない",
            "きっと手配をお願いしない",
          ];
    return option;
  };

  const handleSendMessage = () => {
    if (answers.length >= formatedQuestions.length) {
      return setMessages((old) => [
        ...old,
        { from: "Computer", text: data?.goodbyeMsg },
      ]);
    }

    if (!multiselect) {
      setMsgNo((prev) => prev + 1);
    }

    if (!!multiselect && multiselect?.length) {
      if (multiselect?.length > 0) {
        setTimeout(() => {
          setMessages((old) => [
            ...old,
            {
              from: "Computer",
              text: `${multiselectQuestion} ${
                multiselect?.length < 2
                  ? multiselect[0]
                  : multiselect[repliedMultiselect].toLowerCase()
              }?`,
              question_type: "quick_replies",
              options: optionData(),
            },
          ]);
        }, 1000);

        if (multiselect?.length - 1 > repliedMultiselect) {
          setRepliedMultiselect(repliedMultiselect + 1);
        } else {
          setMultiselect(false);
        }
        setSendButtonEnable(false);
      } else {
        setMultiselect(false);
      }
    }
    if (!!inputMessage.trim().length && !multiselect?.length) {
      setMessages((old) => [...old, { from: "me", text: inputMessage }]);
      setInputMessage("");
    }
    SaveAnswers({
      answers: [
        {
          questionId: formatedQuestions[answers.length]?.id,
          answer: !!inputMessage?.length
            ? inputMessage
            : multiselect?.map((val) => val).join(),
          qNo: answers.length + 1,
        },
      ],
    });
    SaveAnswersToSheet(
      !!inputMessage?.length
        ? inputMessage
        : multiselect?.map((val) => val).join()
    );

    // automaticly send computer message after 1 minute interval
    setTimeout(() => {
      if (answers.length < formatedQuestions.length - 1 && !multiselect) {
        let nextQuestion =
          Number(msgNo) > 12
            ? formatedQuestions[msgNo]
            : formatedQuestions[msgNo + 1];
        // setCheck(false);
        nextQuestion = nextQuestion?.question?.includes("{{ans_1}}")
          ? {
              ...nextQuestion,
              question: nextQuestion?.question?.replaceAll(
                "{{ans_1}}",
                inputMessage
              ),
            }
          : nextQuestion;
        setMessages((old) => [...old, nextQuestion]);
      }

      // checks for last answer, answers array starts from 0
      if (answers.length === formatedQuestions.length - 1) {
        setTimeout(() => {
          if (data?.goodbyeMsg) {
            setMessages((old) => [
              ...old,
              { from: "Computer", text: data?.goodbyeMsg },
            ]);
          }
          if (!!data?.description.length) {
            setMessages((old) => [
              ...old,
              {
                from: "Computer",
                text: data?.description,
                description: true,
              },
            ]);
          }
          if (data?.image) {
            setMessages((old) => [...old, { from: "Computer", image: true }]);
          }
          if (data?.goodbyeURL) {
            setMessages((old) => [
              ...old,
              {
                from: "Computer",
                text: data?.goodbyeURL,
                url: true,
              },
            ]);
          }
          setMessages((old) => [
            ...old,
            { from: "Computer", text: "Thank you for your response!" },
          ]);
        }, 1500);
      }
    }, 1500);
  };
  useEffect(() => {
    if (
      surveys?.[surveyName] &&
      (messages[messages.length - 1].type ||
        messages[messages.length - 1].question_type) !== "text"
    ) {
      setNotAgreedDisable(true);
    } else {
      setNotAgreedDisable(false);
    }
  }, [messages]);

  return (
    <Box pb={"50px"}>
      <Messages
        multiselect={multiselect}
        setMultiselect={setMultiselect}
        setSendButtonEnable={setSendButtonEnable}
        answers={answers}
        formatedQuestions={formatedQuestions}
        setRepliedMultiselect={setRepliedMultiselect}
        repliedMultiselect={repliedMultiselect}
        userAge={userAge}
        isAgeValid={isAgeValid}
        optionData={optionData}
        setNotAgreedDisable={setNotAgreedDisable}
        inputMessage={inputMessage}
        messages={messages}
        updateSurvey={updateSurvey}
        setMsgNo={setMsgNo}
        setAnswerNo={setAnswerNo}
        answerNo={answerNo}
        sessionId={sessionId}
        data={data}
        setUserAge={setUserAge}
        genderNo={genderNo}
        gender={gender}
        setGender={setGender}
        setInputMessage={setInputMessage}
        setMessages={setMessages}
        disable={notAgreedDisable || isInputDisabled}
        isNotAgreedFunction={isNotAgreedFunction}
      />
      <ChatbotFooter
        multiselect={multiselect}
        sendButtonEnable={sendButtonEnable}
        setMultiselect={setMultiselect}
        inputMessage={inputMessage}
        setInputMessage={setInputMessage}
        handleSendMessage={handleSendMessage}
        setMessages={setMessages}
        disable={notAgreedDisable || isInputDisabled}
        answers={answers}
        formatedQuestions={formatedQuestions}
      />
    </Box>
  );
}

export default Chatbot;